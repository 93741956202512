<template>
	<div :class="themeClass" class="loginPassCont width-fill margin-t-10 flex-column bg-fff">
		<el-row>
			<el-col class="font-size18 font-weight700 margin-b-10 titleText">登录密码</el-col>
		</el-row>
		<div class="contentBox width-fill bg-fff">
			<div class="topCont margin-auto">
				<el-steps :active="active" :align-center="true">
					<el-step title="身份认证"></el-step>
					<el-step title="设置操作"></el-step>
					<el-step title="完成"></el-step>
				</el-steps>
			</div>
			<!-- 设置操作 -->
			<div class="botCont margin-auto text-center" v-if="this.active == 2">
				<div class="font-size14 font-color-333 font-weight700 text-left margin-b-13">设置新密码</div>
				<el-form :model="passwordForm" status-icon :rules="passRules" ref="passwordForm">
					<el-form-item label="" prop="passwordText">
					<el-input class="iconfont" v-model="passwordForm.passwordText" :type="passShow?'text':'password'" autocomplete="off"
					 placeholder="请输入6-20位数字、字母或符号的组合">
						<i slot="suffix" class="iconfont pointer" :class="passShow?'icon-eye':'icon-yanjing1'" @click="checkPassIcon()"></i>
					</el-input>
					</el-form-item>
				</el-form>
				<div class="buttonNext combackBtn bg-theme font-color-fff text-center pointer margin-auto" @click="passSumbit('passwordForm')">提交</div>
			</div>
			<!-- 完成 -->
			<div class="botCont margin-auto text-center" v-if="this.active == 3">
				<div class="font-size20 font-weight700 font-color-333 margin-t-25"><span class="iconfont icon-select_full"></span>登录密码设置成功！</div>
				<div class="buttonNext combackBtn bg-theme font-color-fff text-center pointer margin-auto" @click="combackBtn">返回</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		mapGetters
	} from "vuex";
	export default {
		data() {
			return {
				active: 2,
				passwordForm:{
					passwordText: "",
				},
				passShow: false,
				passRules:{
					passwordText:[{
						required: true,
						message: '请输入6-20位数字、字母或符号的组合',
						trigger: 'change' 
					},{
						required: true,
						pattern: /^([a-z0-9\.\@]){6,20}$/i,
						message: '请输入6-20位数字、字母或符号的组合',
						trigger: 'blur'
					}]
				}
			};
		},
		computed: {
			...mapGetters(["getThemeName"]),
			themeClass() {
				return `theme-${this.getThemeName}`;
			},
		},
		methods: {
			//密码可视化
			checkPassIcon() {
				this.passShow = !this.passShow;
			},
			//设置新密码提交
			passSumbit(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.ApiRequestPostNOMess("api/mall/sys/profile/change-password", {
							currentPassword: this.passwordForm.passwordText,
							newPassword: this.passwordForm.passwordText
						}).then(res => {
							this.active = 3;
						}).catch(err => {
						
						})
				
					} else {
						return false;
					}
				});
				
			},
			//返回
			combackBtn() {
				this.$router.go(-1);
			},
		},
	};
</script>

<style scoped lang="scss">
	.bg-theme {
		@include themify($themes) {
			background-color: themed("themes_color");
		}
	}

	.color-theme {
		@include themify($themes) {
			color: themed("themes_color");
		}
	}

	.loginPassCont {
		height: calc(100% - 10px);

		.titleText {
			padding: 8px 14px;
		}
	}

	i,
	span {
		display: inline-block;
	}

	.contentBox {
		height: 100%;

		.topCont {
			width: 500px;
		}

		.botCont {
			width: 400px;
		}

		.topCont {
			margin-bottom: 40px;
			display: flex;
			flex-direction: row;
			justify-content: center;

			.fontIcon {
				width: 32px;
				height: 32px;
				line-height: 32px;
				border: 4px solid #FAFAFA;
				background-color: #E4E4E4;
			}

		}

		.imgBox {
			font-size: 58px;
		}

		.btnText {
			width: 416px;
			height: 56px;
			line-height: 56px;
			margin: 20px auto;
			cursor: default;
		}

		i {
			font-style: normal;
			margin-right: 10px;

		}
	}

	.icon-iconset0291 {
		border-radius: 30px;
		font-size: 50px;
		color: #999;
	}

	::v-deep .el-input__inner {
		height: 35px;
		line-height: 35px;
	}

	.buttonNext {
		height: 45px;
		line-height: 45px;
	}

	.combackBtn {
		/* width: 380px; */
		margin-top: 60px;
	}

	.icon-select_full {
		color: #00CC88;
		font-size: 42px;
		vertical-align: middle;
		margin-right: 10px;
	}

	.codeBox {
		display: flex;
		justify-content: space-between;
		align-items: center;

		.codeInput,
		.codeBtn {
			width: 48%;
		}

		.codeBtn {
			height: 28px;
			line-height: 28px;
			border: 1px solid #eee;

			span {
				vertical-align: middle;
				margin-top: -3px;
			}
		}
	}

	.el-form-item {
		margin-bottom: 15px;
	}
	::v-deep .el-input__suffix{
		right: 0px;
	}
	::v-deep .el-dialog {
		height: 510px;
	}

	/* 步骤条 */
	::v-deep .el-steps--horizontal {
		width: 100%;
	}

	::v-deep .el-step__line-inner {
		height: 10px;
		background-color: #00cc88;
		border-color: #00cc88;
	}

	::v-deep .el-step__icon.is-text {
		background: #00cc88;
		color: #fff;
		border: 5px solid
	}

	::v-deep .is-finish .el-step__icon.is-text {
		border: 5px solid #DFFFF5;
	}

	::v-deep .el-step__icon {
		height: 50px;
		width: 50px;
	}

	::v-deep .el-step.is-horizontal .el-step__line {
		height: 10px;
		background: #e4e4e4;
		margin-top: 9px;
	}

	::v-deep .is-success {
		border-color: #dffff5;
		/* color: #00cc88; */

		.el-step__icon.is-text {
			background: #00cc88;
			color: #dffff5;
		}
	}

	::v-deep .is-process {
		border-color: #fafafa;
		color: #999;

		.el-step__icon.is-text {
			background: #e4e4e4;
			color: #fafafa;
		}

		.el-step__icon-inner {
			color: #999;
		}
	}
	/deep/ .el-input__validateIcon{
		display: none;
	}
	::v-deep .is-wait {
		border-color: #fafafa;
		color: #999;

		.el-step__icon.is-text {
			background: #e4e4e4;
			color: #fafafa;
		}
	}

	::v-deep .el-step__title.is-process {
		font-weight: 400;
		// color: #999999;
	}

	::v-deep .el-step__title.is-wait {
		font-weight: 400;
		// color: #999999;
	}

	::v-deep .el-step__title.is-finish {
		color: #00cc88;
	}

	/* 步骤条结束 */
</style>
